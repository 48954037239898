import {Paragraph} from "../models/paragraph";
import ImageLeftContentRight from "./ImageLeftContentRight";
import {Spacer} from "./Spacer";
import {Link, Typography} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";

export const ParagraphsComponent = ({paragraphs}: { paragraphs: Paragraph[] }) => {
    return (
        <div>
            {paragraphs.length === 0 ?
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "normal"
                    }}
                >
                    Content coming soon...
                </Typography>
                : <div>
                    {paragraphs.map((paragraph, index) => (
                        <div key={index}>
                            {/*{index % 2 === 0*/}
                            {/*    ?*/}
                            <ImageLeftContentRight
                                imageUrl={paragraph.imageUrl}
                                content={ParagraphComponent(paragraph)}
                            />
                            {/*    : <ImageRightContentLeft*/}
                            {/*        imageUrl={paragraph.imageUrl}*/}
                            {/*        content={ParagraphComponent(paragraph)}*/}
                            {/*    />*/}
                            {/*}*/}
                            {/*<div>*/}
                            {/*    {index !== homeContent.paragraphs.length - 1*/}
                            {/*        ? <><Spacer height={150}/><Divider/><Spacer height={150}/></>*/}
                            {/*        : */}
                            <><Spacer height={150}/></>
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

const ParagraphComponent = (paragraph: Paragraph) => {
    return (
        <div>
            <h3>{paragraph.title}</h3>
            <ul>
                {paragraph.textContent.map((sentence, index) => (
                    <li>
                        <Typography
                            variant="body1"
                            paragraph
                            key={index}
                            sx={{
                                wordBreak: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "normal"
                            }}
                        >
                            {sentence}
                        </Typography>
                    </li>
                ))}
            </ul>
            {paragraph.links.map((link, index) => (
                <Link component={RouterLink} to={link.linkUrl} variant="body1" key={index}>
                    {link.linkText}
                </Link>
            ))}
        </div>
    )
}