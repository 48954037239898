import homeContent from './home-content.json';
import startContent from './start-content.json';
import FloatingBackButton from "../components/FloatingBackButton";
import ParallaxPage from "../components/ParallaxPage";
import React from "react";
import {Spacer} from "../components/Spacer";
import {ParagraphsComponent} from "../components/ParagraphsComponent";

export const Home = () => {
    return (
        <div>
            <FloatingBackButton/>
            <div>
                <FloatingBackButton/>
                <ParallaxPage
                    imageUrl={startContent.home.imageUrl}
                    content={
                        <div>
                            <h1>{homeContent.title}</h1>
                            <h2>{homeContent.subtitle}</h2>
                            <Spacer height={100}/>
                            <ParagraphsComponent paragraphs={homeContent.paragraphs} />
                        </div>
                    }
                />
            </div>
        </div>
    );
};