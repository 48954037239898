import startContent from "./start-content.json";
import FloatingBackButton from "../components/FloatingBackButton";
import ParallaxPage from "../components/ParallaxPage";
import React from "react";
import appsContent from "./apps-content.json";
import {Spacer} from "../components/Spacer";
import {ParagraphsComponent} from "../components/ParagraphsComponent";

export const Apps = () => {
    return (
        <div>
            <FloatingBackButton/>
            <ParallaxPage
                imageUrl={startContent.apps.imageUrl}
                content={
                    <div>
                        <h1>{appsContent.title}</h1>
                        <h2>{appsContent.subtitle}</h2>
                        <Spacer height={100}/>
                        <ParagraphsComponent paragraphs={appsContent.paragraphs} />
                    </div>
                }
            />
        </div>
    );
};